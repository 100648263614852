import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  HeroContainer,
  LeftHeroContainer,
  OpinionsContainer,
  RelativeContainer
} from './styled.components';
import {
  ButtonContainer,
  FlexColumnContainer,
  H1Style,
  ParagraphMediumStyle,
  PurpleButton,
  TransparentButton
} from '../../styled.components';
import Outsource2Icon from './components/Outsource2Icon';
import { StaticImage } from 'gatsby-plugin-image';
import '../../../../images/for-companies/hs/circle.webp';
import GifComponent from '../gif-component';

import Gif1Video from '../../../../assets/videos/companies/hs_gif1.mp4';

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeroContainer>
        <div className="container">
          <LeftHeroContainer>
            <FlexColumnContainer alignItems="flex-start" gap="40px">
              <Outsource2Icon />
              <FlexColumnContainer alignItems="flex-start" gap="8px">
                <H1Style fontWeight="275">{t`for-companies.hs-title`}</H1Style>
                <H1Style>{t`for-companies.hs-title2`}</H1Style>
              </FlexColumnContainer>
            </FlexColumnContainer>
            <FlexColumnContainer alignItems="flex-start" gap="24px">
              <FlexColumnContainer alignItems="flex-start" gap="8px">
                <OpinionsContainer>
                  <ParagraphMediumStyle>4.87 (1k+)</ParagraphMediumStyle>
                  <StaticImage
                    className="stars-image"
                    src="../../../../images/for-companies/hs/stars.webp"
                    alt="image"
                    placeholder="none"
                    loading="lazy"
                  />
                </OpinionsContainer>
                <ParagraphMediumStyle fontWeight={350}>
                  <a href="#opinions">{t`for-companies.hs-see-opinion`}</a>
                </ParagraphMediumStyle>
              </FlexColumnContainer>
              <ButtonContainer>
                <TransparentButton
                  as="a"
                  href="#services">{t`for-companies.hs-offer`}</TransparentButton>
                <PurpleButton
                  as="a"
                  target="_blank"
                  rel="noreferrer"
                  href="https://calendar.app.google/8aEj3y4G6Pbj4yVq6">{t`for-companies.hs-meeting`}</PurpleButton>
              </ButtonContainer>
            </FlexColumnContainer>
          </LeftHeroContainer>
          <RelativeContainer>
            {/* <StaticImage
              className="circle-image"
              src="../../../../images/for-companies/hs/circle.webp"
              alt="image"
              placeholder="none"
              loading="lazy"
            /> */}
            <GifComponent className="circle-gif" src={Gif1Video} />
            <StaticImage
              className="kacper-image"
              src="../../../../images/for-companies/hs/kacper.webp"
              alt="image"
              placeholder="none"
              loading="lazy"
            />
          </RelativeContainer>
        </div>
      </HeroContainer>
    </>
  );
};

export default HeroSection;
